.TextBlurbContainer {
  color: white;
  margin: 2%;
  border: 2px rgb(128,128,128) solid;
  border-radius: 6px;
  overflow: hidden;

  h1 {
    padding-left: 10px;
  }

  hr {
    margin: 0;
  }

  .TextBlurbChildren {
    padding: 1%;
  }
}