.NavigationBarBase {
  .NavigationBar {
    background-color: rgb(52,52,52);

    hr{
      margin: 0;
    }

    a:link,
    a:visited,
    a:active,
    a:hover {
      text-decoration: none;
      color: white;
    }

    a {
      display: inline-block;
    }

    .Spacer {
      display: inline-block;
      width: 20px;
    }
  }
}

.NavigationBarFixed {
  top: 0;
  z-index: 1000;
  position: fixed;
  width: 100%;
}