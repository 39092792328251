// Variables
$logo_size : 160px;

.Logo {
    width: $logo_size;
    height: $logo_size;
    float: left;
}

.LogoText {
    height: $logo_size;
    margin-left: -$logo_size
}